<template>
  <v-container class="custom-page-header-count py-0 px-0">
    <v-layout>
      <h1 class="text-h5 mb-0 custom-header-blue-text">
        <v-select
          :items="statusList"
          v-model="status"
          hide-details
          item-color="cyan"
          @change="setStatus"
          class="pt-0 mt-0 listing-status main-listing-status-filter"
          item-text="text"
          item-value="dbvalue"
          :menu-props="{
            contentClass: 'test-list',
          }"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-avatar
                :size="30"
                :color="item.color"
                class="white--text"
                :text-color="item.textcolor"
              >
                <template v-if="item && item.dbvalue && item.dbvalue == 1">{{
                  item.total
                }}</template>
                <template v-else> {{ item.employee_status_count }}</template>
              </v-avatar>
            </v-list-item-action>
          </template>
        </v-select>
      </h1>
      <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span class="text-h5 custom-header-blue-text " v-bind="attrs" v-on="on">{{ currentStatus.text }}<v-icon class="custom-header-blue-text ">mdi-chevron-down</v-icon></span>
            </template>
            <v-list>
              <v-list-item @click="setStatus(data)" v-for="(data, index) in statusList" :key="index">
                <v-list-item-title>{{ data.text }}</v-list-item-title>
                <v-list-item-avatar class="my-0">
                  <v-avatar :color="data.color" size="30">
                    <span class="white--text text-h6">{{ data[countkey] }}</span>
                  </v-avatar>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-menu> -->
      <!-- v-if="dataLoading" -->
      <template v-if="false">
        <template v-for="(data, index) in dataLoadingList">
          <v-flex
            :key="index"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !dataLoadingList[index + 1],
              'mx-1': index != 0 && dataLoadingList[index + 1],
            }"
          >
            <div :class="'card m-0 top_card card-custom gutter-b border-' + data.headercolor">
              <div class="card-body p-0">
                <div :class="'card-rounded-bottom mid_part '">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span class="symbol symbol-circle symbol-50 symbol-light-danger mr-2">
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-' + data.headercolor">
                          <inline-svg :src="$assetURL('media/custom-svg/' + moduleType + '.svg')" />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right">
                      <h5 class="text-dark-75 font-weight-bold mt-2 min-width-150px">
                        <v-skeleton-loader class="custom-skeleton" type="text"></v-skeleton-loader>
                      </h5>
                      <span :class="'qnt_val font-weight-bolder text-' + data.headercolor">
                        <v-progress-circular
                          indeterminate
                          :color="data.headercolor"
                        ></v-progress-circular>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
      <template v-if="false">
        <template v-for="(data, index) in statusList">
          <v-flex
            :key="index"
            class="py-0"
            v-if="data.text"
            :class="{
              'mr-1': index == 0,
              'ml-1': !statusList[index + 1],
              'mx-1': index != 0 && statusList[index + 1],
            }"
          >
            <div :class="'card m-0 top_card card-custom gutter-b border-' + data.headercolor">
              <div class="card-body p-0">
                <div
                  :class="
                    'card-rounded-bottom mid_part shadow-sm bg-light-' +
                    data.color +
                    ' bg-front-' +
                    data.textcolor
                  "
                >
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span class="symbol symbol-circle symbol-50 symbol-light-danger mr-2">
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-' + data.color">
                          <inline-svg :src="$assetURL('media/custom-svg/' + moduleType + '.svg')" />
                        </span>
                      </span>
                    </span>

                    <div class="d-flex flex-column text-right bTitle">
                      <!-- <router-link
                         
                          > -->

                      <a :href="href" v-on:click="navigate">
                        <h5 class="text-dark-75 font-weight-bold mt-2">
                          {{ data.text }}
                        </h5>
                        <template v-if="data.value == 'all'">
                          <span :class="'qnt_val font-weight-bolder text-' + data.color">{{
                            data[allkey]
                          }}</span>
                        </template>
                        <template v-else>
                          <span :class="'qnt_val font-weight-bolder text-' + data.color">{{
                            data[countkey]
                          }}</span>
                        </template>
                      </a>
                      <!-- </router-link> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
        <template v-for="(data, index) in extraList">
          <v-flex
            :key="`extra-${index}`"
            class="py-0"
            :class="{
              'mr-1': index == 0,
              'ml-1': !extraList[index + 1],
              'mx-1': index != 0 && extraList[index + 1],
            }"
          >
            <div :class="'card m-0 top_card card-custom gutter-b border-primary'">
              <div class="card-body p-0">
                <div class="card-rounded-bottom mid_part">
                  <div
                    class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
                  >
                    <span class="symbol symbol-circle symbol-50 symbol-light-danger mr-2">
                      <span class="symbol-label">
                        <span :class="'svg-icon svg-icon-xl svg-icon-primary'">
                          <inline-svg :src="$assetURL('media/custom-svg/' + moduleType + '.svg')" />
                        </span>
                      </span>
                    </span>
                    <div class="d-flex flex-column text-right bTitle">
                      <router-link>
                        <a :href="href" v-on:click="navigate">
                          <h5 class="text-dark-75 font-weight-bold mt-2">
                            {{ data.title }}
                          </h5>
                          <span :class="'qnt_val font-weight-bolder text-primary'">{{
                            data.value
                          }}</span>
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </template>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    /*  stat: {
          type: [Number],
          required: true,
          default: 1,
        }, */
    dataLoading: {
      type: [Boolean],
      required: true,
      default: false,
    },
    moduleType: {
      type: [String],
      required: true,
      default: "purchaseorder",
    },
    allkey: {
      type: [String],
      required: true,
      default: "all_purchase",
    },
    countkey: {
      type: [String],
      required: true,
      default: "customer_status_count",
    },
    statusList: {
      type: Array,
      default() {
        return new Array();
      },
    },
    extraList: {
      type: [Array],
      default() {
        return new Array();
      },
    },
    stat: {
      type: Number,

      default: 1,
    },
  },
  watch: {
    stat(param) {
      if (param) {
        this.status = param;
      }
    },

    currentStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "param");
        this.$emit("saveAddress", param);
      },
    },
    /*   stat(){ 
          this.filterProductss = find(this.statusList, {
            dbvalue:this.stat,
          });
          this.currentStatus.text = this.filterProductss.text
        } */
  },
  data() {
    return {
      filterProductss: null,
      // currentStatus: { text: "All Purchase Order", value: "all" },
      status: 1,
      dataLoadingList: [
        {
          headercolor: "primary",
          bgcolor: "bg-light-warning",
        },
        {
          headercolor: "primary",
          bgcolor: "bg-light-success",
        },
        {
          headercolor: "primary",
          bgcolor: "bg-light-info",
        },
      ],
    };
  },
  methods: {
    setStatus(statusValue) {
      this.$emit("saveAddress", statusValue);
    },
  },
};
</script>
